import { Component } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
declare var $: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "makara-phone-shop";
  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      (<any>window).ga('set', 'page', evt.urlAfterRedirects);
      (<any>window).ga('send', 'pageview');
      window.scrollTo(0, 0);
    });
  }
}
