import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BannerService {
    constructor(private http: HttpClient, private api: GlobalService) {}

    getBanner(type) {
        // type: slide, adsTop, adsBottom, adsLeft, adsRight

        const query = 'banners?type=';
        return this.http.get(this.api.endpoint + query + type, {
            headers: this.api.headers
        });
    }

    getMenu() {
        const query = 'menus';
        return this.http.get(this.api.endpoint + query, {
            headers: this.api.headers
        });
    }
}
