import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Banner } from 'src/app/templates/home/home.model';
import { BannerService } from 'src/app/shared/services/banner.service';

@Component({
    selector: 'app-ads',
    templateUrl: './ads.component.html',
    styleUrls: ['./ads.component.scss']
})
export class AdsComponent implements OnInit {
    banners: Banner;
    @Input() adsType: string;
    constructor(private bannerService: BannerService, private elementRef: ElementRef) {
        this.adsType = this.elementRef.nativeElement.getAttribute('adsType');
    }
    ngOnInit() {
        // console.log(this.adsType);
        // this.adsType = !this.adsType ? 'phone' : '';
        switch (this.adsType) {
            case 'phones':
                this.adsType = 'phone';
                break;
            case 'accessories':
                this.adsType = 'accessory';
                break;
            case 'tablets':
                this.adsType = 'tablet';
                break;
            case 'watches':
                this.adsType = 'watch';
                break;
            default:
                'phones';
                break;
        }

        this.bannerService.getBanner(this.adsType).subscribe((res: Banner) => {
            this.banners = res;
        });
    }
}
