import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule } from '@angular/forms';
import { AccessoryBrandComponent } from './partial/accessory-brand/accessory-brand.component';
import { AccessoryTypeComponent } from './partial/accessory-type/accessory-type.component';
import { AdsComponent } from './partial/ads/ads.component';
import { VerticalComponent } from './partial/ads/vertical/vertical.component';

@NgModule({
    declarations: [
        HeaderComponent,
        SidebarComponent,
        FooterComponent,
        AccessoryBrandComponent,
        AccessoryTypeComponent,
        AdsComponent,
        VerticalComponent
    ],
    imports: [CommonModule, RouterModule, FormsModule],
    exports: [
        HeaderComponent,
        SidebarComponent,
        FooterComponent,
        AccessoryBrandComponent,
        AccessoryTypeComponent,
        AdsComponent,
        VerticalComponent
    ]
})
export class IncludesModule {}
