import { Brand } from './sidebar.model';
import { BrandService } from './../../shared/services/brand.service';
import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    constructor(private brandService: BrandService) {}
    brands: Brand;
    ngOnInit() {
        this.brandService.getBrandSidebar().subscribe(
            (res: Brand) => {
                this.brands = res;
            },
            err => {
                console.log(err);
            }
        );
    }
}
