import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';

@Injectable({
    providedIn: 'root'
})
export class ContactService {
    constructor(private http: HttpClient, private api: GlobalService) {}

    getContactDetail() {
        const query = 'contact-us';
        return this.http.get(this.api.endpoint + query, {
            headers: this.api.headers
        });
    }
}
