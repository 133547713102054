import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';

@Injectable({
    providedIn: 'root'
})
export class AccessaryService {
    constructor(private http: HttpClient, private api: GlobalService) {}

    getType() {
        const query = 'accessory-types';
        return this.http.get(this.api.endpoint + query, {
            headers: this.api.headers
        });
    }

    getBrand() {
        const query = 'brands?type=accessory';
        return this.http.get(this.api.endpoint + query, {
            headers: this.api.headers
        });
    }

    getAccessory(getBy, value) {
        const query = `accessories?${getBy}=${value}`;

        return this.http.get(this.api.endpoint + query, {
            headers: this.api.headers
        });
    }

    getDeviceList(
        page = 1,
        accessoryType,
        brandName = '',
        minRange = 100,
        maxRange = 3000,
        sortDatePrice = 'new',
    ) {
        let query;
        query = `accessories?limit=28&offset=${page}&accessoryType=${accessoryType}&brand=${brandName}&priceRange=${minRange},${maxRange}&sortDatePrice=${sortDatePrice}`;
        return this.http.get(this.api.endpoint + query, {
            headers: this.api.headers
        });
    }

    getDetail(url) {
        const query = `accessories/${url}`;

        return this.http.get(this.api.endpoint + query, {
            headers: this.api.headers
        });
    }

    getRelated(brand, accessoryType) {
        const query = `accessories?limit=6&brand=${brand}&accessoryType=${accessoryType}`;
        return this.http.get(this.api.endpoint + query, {
            headers: this.api.headers
        });
    }
}
