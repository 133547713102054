import { Component, OnInit } from '@angular/core';
import { AccessaryService } from 'src/app/shared/services/accessary.service';
import { Brand } from '../../sidebar/sidebar.model';

@Component({
    selector: 'app-accessory-brand',
    templateUrl: './accessory-brand.component.html',
    styleUrls: ['./accessory-brand.component.scss']
})
export class AccessoryBrandComponent implements OnInit {
    constructor(private AccessaryService: AccessaryService) {}
    brands: Brand;
    ngOnInit() {
        this.AccessaryService.getBrand().subscribe((res: Brand) => {
            this.brands = res;
            // console.log(res);
        });
    }
}
