import { GlobalService } from './global.service';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class BrandService {
    constructor(private http: HttpClient, private api: GlobalService) {}

    getBrandSidebar() {
        const query = 'brands?type=device';
        return this.http.get(this.api.endpoint + query, {
            headers: this.api.headers
        });
    }
}
