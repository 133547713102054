import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, Event } from '@angular/router';
import { BannerService } from 'src/app/shared/services/banner.service';
import { Menu } from './header.model';
declare var $: any;
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    count = 1;
    menu: Menu;
    constructor(private router: Router, private bannerService: BannerService) {
        // On route change hide the search box
        router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                $('#hide,.wrap-search').addClass('d-none');
                $('#show').removeClass('d-none');
            }
        });
    }
    keyword: string;
    ngOnInit() {
        this.bannerService.getMenu().subscribe((res: Menu) => {
            this.menu = res;
        });
    }

    toggleSearch() {
        $('#show, #hide,.wrap-search').toggleClass('d-none');
        $('.wrap-search input').removeClass('error');
    }

    toSearch() {
        if (this.keyword === '' || this.keyword === null) {
            $('.wrap-search input').addClass('error');
            return;
        } else {
            $('.wrap-search input').removeClass('error');
            this.router.navigate(['/search', this.keyword]);
        }
    }
}
