import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class GlobalService {
  constructor() {}
  endpoint = environment.apiUrl;
  xapi = environment.xapi;
  headers = new HttpHeaders({
    "x-api-key": this.xapi
  });
}
