import { Component, OnInit } from '@angular/core';
import { BannerService } from 'src/app/shared/services/banner.service';
import { Banner } from 'src/app/templates/home/home.model';
import { Menu } from '../header/header.model';
import { ContactService } from 'src/app/shared/services/contact.service';
import { Contact } from './footer.model';
declare var $;
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    currentYear = new Date().getFullYear();
    banners: Banner;
    menu: Menu;
    contacts: Contact;
    constructor(private bannerService: BannerService, private contactService: ContactService) {}

    ngOnInit() {
        this.bannerService.getBanner('adsBottom').subscribe((res: Banner) => {
            this.banners = res;
        });

        this.bannerService.getMenu().subscribe((res: Menu) => {
            this.menu = res;
        });

        this.contactService.getContactDetail().subscribe((res: Contact) => {
            // console.log('footer', res);

            this.contacts = res;
        });

        // ===== Scroll to Top ====
        $(window).scroll(function() {
            if ($(this).scrollTop() >= 50) {
                // If page is scrolled more than 50px
                $('#return-to-top').fadeIn(200); // Fade in the arrow
            } else {
                $('#return-to-top').fadeOut(200); // Else fade out the arrow
            }
        });
        $('#return-to-top').click(function() {
            // When arrow is clicked
            $('body,html').animate(
                {
                    scrollTop: 0 // Scroll to top of body
                },
                500
            );
        });
    }
}
