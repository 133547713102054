import { Component, OnInit } from '@angular/core';
import { BannerService } from 'src/app/shared/services/banner.service';
import { Banner } from 'src/app/templates/home/home.model';

@Component({
    selector: 'app-vertical',
    templateUrl: './vertical.component.html',
    styleUrls: ['./vertical.component.scss']
})
export class VerticalComponent implements OnInit {
    constructor(private bannerService: BannerService) {}
    banners: Banner;
    ngOnInit() {
        this.bannerService.getBanner('adsLeft').subscribe((res: Banner) => {
            this.banners = res;
            // console.log(this.banners);
        });
    }
}
